const axios = require('axios')
const javascriptURL = 'https://onboarding-backend-d7kg.vercel.app/plan'
//const url = 'dentalmenu.local/v2/plan'
const dev = false;
const url = dev ? "http://127.0.0.1:8000/" : "https://app.dentmenu.com/v2/"
import validator from 'validator'

let mode = 'python'
let get_mode = 'python'

export function InputToInteger(str) {
    str = str.replace(/\D/g, '');
    return +str;
}

export function ConvertInts(plan) {
    plan.rewardsPointsPerVisit = parseInt(plan.rewardsPointsPerVisit)
    plan.enrollmentFeeFirstVisit = parseInt(plan.enrollmentFeeFirstVisit)
}

export async function SendPlanInformation(plan, isIntake = false) {
    if(plan.practiceName === null) {
        return;
    }
    ConvertInts(plan);
    delete plan.allStates;
    let json = {
        practiceName: plan.practiceName,
        plans: JSON.stringify(plan),
        isIntake: isIntake,
        //plans: {name: "Jason", frequency: 6}
    }
    //console.log("Sending Post request now- data = ", json);
    if(mode !== 'python'){
        try {
            const response = await axios.post(javascriptURL, json);
        ///console.log("Returning from API call- data = ", response.data);
            return response.data;
        } catch (error) {
            if(error.response.status === 500) {
                //console.log("500 from UpdatePlan")
                return 500
            }
            //console.log("Error encountered in SendPlanInformation. Error = ", error);
            throw error; // Re-throw the error if you want calling code to handle it.
        }
    }
    else if(mode === 'python') {
        try {
            const response = await axios.post(url + 'add-plan/', json);
        ///console.log("Returning from API call- data = ", response.data);
            return response.data['key'];
        } catch (error) {
            if(error.response.status === 500) {
                //console.log("500 from UpdatePlan")
                return 500
            }
            //console.log("Error encountered in SendPlanInformation. Error = ", error);
            throw error; // Re-throw the error if you want calling code to handle it.
        }
    }
}

export function GetPlanFromDB(planCode) {
    let fullUrl = `${javascriptURL}?key=${planCode}`;
    // Return the axios promise directly
    if(get_mode !== 'python'){
        return axios.get(fullUrl).then(function (response) {
            return response.data
        })
        .catch(function (error) {
            if (error.response) {
                if(error.response.status === 404) {
                    return 404;
                }
                else if(error.response.status === 500) {
                    return 500;
                }
            }
            throw error;
        });
    }
    else {
        let fullPythonUrl = `${url}/plan?key=${planCode}`;
        return axios.get(fullPythonUrl).then(function (response) {
            return response.data
        })
        .catch(function (error) {
            if (error.response) {
                if(error.response.status === 404) {
                    return 404;
                }
                else if(error.response.status === 500) {
                    return 500;
                }
            }
            throw error;
        });
    }
    
}

export async function UpdatePlanDataInDB(inputId, plan, changelog) {
    let newPlan = JSON.parse(JSON.stringify(plan));
    delete newPlan.id;
    delete newPlan.allStates;
    delete newPlan.uneditedState;
    ConvertInts(plan);
    let json = {
        id: inputId,
        plans: JSON.stringify(newPlan),
        changes: changelog
    }
    if(mode !== 'python') {
        try {
            const response = await axios.put(javascriptURL, json);
            //console.log("PUT response data = ", response.data)
            return response.data; // This ensures that the data is returned from the function.
        } catch (error) {
            if(error.response.status === 404) {
                //console.log("404 from UpdatePlan")
                return 404;
            }
            else if(error.response.status === 500) {
                //console.log("500 from UpdatePlan")
                return 500
            }
            //console.log("Error encountered in UpdatePlanDataInDB. Error = ", error);
            throw error; // Re-throw the error if you want calling code to handle it.
        }
    }
    else if(mode === 'python') {
        try {
            //console.log("Json to submit = ", json)
            const response = await axios.put(`${url}plan`, json);
            //console.log("PUT response data = ", response.data)
            return response;
        } catch (error) {
            if(error.response.status === 404) {
                //console.log("404 from UpdatePlan")
                return 404;
            }
            else if(error.response.status === 500) {
                //console.log("500 from UpdatePlan")
                return 500
            }
            //console.log("Error encountered in UpdatePlanDataInDB. Error = ", error);
            throw error; // Re-throw the error if you want calling code to handle it.
        }
    }
}

export async function SubmitPlanToDB(planKey, extraData) {
    let result = await axios.post(url + `submit?key=` + planKey, extraData);
    return result;
}

export async function CreateLandingPageUtil(planKey, extraData) {
    let result = await axios.post(url + `/create_landing_page?key=` + planKey, extraData);
    return result;
}

export function GoToComponent(instance, num) {
    //console.log("Emitting from GoToComponent");
    instance.$emit('switchComponent', { number: num });
}

export function AddRewardsCostToPrice(store, plan, basePrice) {
    return basePrice + ((store.state.rewardsPointsPerVisit / plan.frequency) * (store.state.reimbursementPercentage / 100))
}

export function AddDMFeeToPrice(store, basePrice) {
    return basePrice + store.state.dentalMenuFee;
}

export function AddProcessingFeeToPrice(basePrice) {
    return (basePrice * 1.029) + 0.3;
}

export function CalculateComputedPlanPrice(store, plan) {
    //console.log("Entering calculate price")
    if(plan.monthlyPriceAltered) {
        return plan.price;
    }
    let basePrice = 0;
    store.getters.benefitsByType(plan.planType).forEach((benefit) => {
        let benefitFrequency = benefit.frequencies[plan.planType] === -1 ? plan.frequency : benefit.frequencies[plan.planType];
        let benefitPrice = benefit.prices[plan.planType] / (benefitFrequency * (1 + (store.state.percentBreakage[plan.planType] / 100)));
        basePrice += benefitPrice;
        //console.log("Benefit = ", benefit.name, " , benefitPrice per month = ", benefitPrice, " , frequency = ", (benefit.isAnnual ? 12 : plan.frequency));
    });
    let rewardsAdjustedPrice = basePrice;
    if(store.state.includePointsInPlanPrice) {
        rewardsAdjustedPrice = AddRewardsCostToPrice(store, plan, basePrice);
    }
    let merchantFeeAdjustedPrice = store.state.includeProcessingFee ? AddProcessingFeeToPrice(rewardsAdjustedPrice) : rewardsAdjustedPrice;
    let DMFeeAdjustedPrice = store.state.includeDMFee ? AddDMFeeToPrice(store, merchantFeeAdjustedPrice) : merchantFeeAdjustedPrice;
    let roundedPrice = Math.round(DMFeeAdjustedPrice);
    return roundedPrice
}

export async function IsLandingPageAvailable(landingPageURL) {
    //return true;
    try {
        let response = null
        if(dev === true) {
            response = await axios.get('http://dentalmenu.local/v2/check-landing-page-url', {
                params: {
                    landingPage: landingPageURL
                }
            });
        }
        else {
            response = await axios.get("https://app.dentmenu.com/v2/check-landing-page-url?landingPage=kolla-test", {
                params: {
                    landingPage: landingPageURL
                }
            });
        }
        //console.log("API returned ", response.data.result);
        return !response.data.result;
    } catch (error) {
        console.error("Error checking landing page:", error);
        throw error;
    }
}

export function ValidateWebsite(website) {
    return validator.isURL(website, {
        require_host: true,
        require_tld: true,
        allow_underscores: false,
        host_whitelist: false,
        host_blacklist: false,
        allow_trailing_dot: false,
        allow_protocol_relative_urls: false,
        disallow_auth: true
    });
}

export function ValidateEmail(email) {
    return email === '' || validator.isEmail(email, {
        allow_display_name: false,
        require_display_name: false,
        allow_utf8_local_part: true,
        require_tld: true,
        allow_ip_domain: false,
        domain_specific_validation: true
    });
}

export async function CheckLandingPageURL(landingPage) {
    if (landingPage.length === 0) {
        return { isAvailable: false, error: null };
    }
    try {
        let response = null;
        if(dev === true) {
            response = await axios.get('http://dentalmenu.local/v2/check-landing-page-url', {
                params: {
                    landingPage: landingPage
                }
            });
        }
        else {
            response = await axios.get("https://app.dentmenu.com/v2/check-landing-page-url?landingPage=kolla-test", {
                params: {
                    landingPage: landingPage
                }
            });
        }
        return { isAvailable: !response.data.result, error: null };
    } catch (error) {
        console.error("Error checking landing page:", error);
        return { isAvailable: false, error };
    }
}