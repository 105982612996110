<template>
    <div :class="RowClass">
        <div style="display: flex; flex: 1;">
            <div class="checkbox-container">
                <!-- <input type="checkbox" id="checkbox" v-model="discountActive" class="custom-checkbox grow-medium"> -->
                <div class="checkbox-container grow-medium" style="position:relative;">
                        <div class="checkbox" @click="toggleChecked($event)">
                            <div class="checkbox-fill" v-if="this.discountActive">
                                <p class="checkbox-text">✓</p>
                            </div>
                        </div>
                    </div>
                    <div class="tooltip" v-if="displayWarning">
                        <p>Fill out all fields to create this discount</p>
                    </div>
            </div>
            <div class="text-holder">
                <input type="text" v-model="localTitle" class="title text-field" :class="{'text-field-correct': titleCorrect,
                    'text-field-incorrect': (this.userTriedToCheck && !titleCorrect), 'hover-grow': !isReadOnly, 'text-field-active': !isReadOnly}" @input="UpdateDiscount()" :readonly="isReadOnly"/>
                <input type="text" v-model="localDescription" class="description hover-grow text-field text-field-active" :class="{'text-field-correct': descriptionCorrect,
                    'text-field-incorrect': (this.userTriedToCheck && !descriptionCorrect)}" @input="UpdateDiscount()"/>
            </div>
        </div>
        <div class="discount-details">
            <div>
                <PlusMinusButton :char="'+'" @plusMinusClick="UpdatePercentOff"/>
                <PlusMinusButton :char="'-'" @plusMinusClick="UpdatePercentOff"/>
            </div>
            <div class="bubble no-select" :class="{'bubble-incorrect': (this.userTriedToCheck === true && !bubbleCorrect)}">{{ localPercentOff }}<span class="percent-sign no-select">%</span></div>
            <div><h2> Off</h2></div>
        </div>
    </div>
</template>

<script>
import PlusMinusButton from './PlusMinusButton.vue'
import { mapActions } from 'vuex';

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    components: {
        PlusMinusButton,
    },
    data() {
        return {
            discountActive: true,
            localPercentOff: 2,
            localTitle: "",
            localDescription: "",
            userTriedToCheck: false,
            isPerio: null,
            isRestorative: null,
        }
    },
    methods: {
        ...mapActions(['updateTreatmentDiscountByID']),
        UpdateDiscount() {
            let newDC = {
                id: this.id,
                title: this.localTitle,
                description: this.localDescription,
                percentOff: this.localPercentOff,
                isActive: this.discountActive,
                isPerio: this.isPerio,
                isRestorative: this.isRestorative
            };
            this.updateTreatmentDiscountByID(newDC);
        },
        UpdatePercentOff(value) {
            if((value === -1 && this.localPercentOff === 0) || (value === 1 && this.localPercentOff === 100)) {
                return
            }
            else {
                this.localPercentOff += (value * 5)
            }
            this.UpdateDiscount()
        },
        toggleChecked() {
            this.userTriedToCheck = false;
            if(this.hasDefaultValues) {
                this.userTriedToCheck = true;
            }
            else {
                this.discountActive = !this.discountActive
            }
            this.UpdateDiscount()
        }
    },
    computed: {
        RowClass() {
            return {
                'full-row': true,
                'bg-gray-global': (this.id % 2) === 0
            }
        },
        titleCorrect() {
            return this.localTitle !== "Type to Add Another"
        },
        descriptionCorrect() {
            return this.localDescription !== "Description"
        },
        bubbleCorrect() {
            return this.localPercentOff > 0;
        },
        
        hasDefaultValues() {
            if(!this.titleCorrect || !this.descriptionCorrect || !this.bubbleCorrect) {
                return true;
            }
            return false;
        },
        displayWarning() {
            return (this.hasDefaultValues && this.userTriedToCheck)
        },
        isReadOnly() {
            if(this.id === 0 || this.id === 1) {
                return true;
            }
            else return false;
        }
    },
    created() {
        let discount = this.$store.getters.getDiscountByID('T', this.id);
        this.localTitle = discount.title;
        this.localDescription = discount.description;
        //console.log("IsActive = ", this.isActive)
        this.discountActive = discount.isActive;
        this.localPercentOff = discount.percentOff;
        this.isPerio = discount.isPerio;
        this.isRestorative = discount.isRestorative;
    },
}
</script>

<style scoped>
.full-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 70px;
    max-height: 80px;
    width: 95%;
}

.checkbox-container {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 3.5%;
}

.checkbox {
    position: relative;
    width: 25px;
    height: 25px;
    border: 2px solid var(--color-dark-blue);
    border-radius: 5px;
    cursor: pointer;
}

.checkbox-fill {
    position: absolute;
    left: 1px;
    top: 1px;
    width: 23px;
    height: 23px;
    background-color: var(--color-dark-blue);
    border-radius: 3px;
    color: white;
}

.checkbox-text {
    margin: 0;
    font-size: 1.5em;
    position: absolute;
    bottom: -3px;
    left: 2px;
}

.tooltip {
    position: absolute;
    background-color: var(--color-dark-blue);
    width: 130px;
    margin: 0;
    bottom: -21px;
    right: 50px;
}

.tooltip::after { /* This is the little arrowhead */
  content: "";
  position: absolute;
  right: -17px; /* Adjust based on the size of the arrow */
  top: 23px; /* Adjust to align the arrow with the middle of the box, if necessary */
  width: 0;
  height: 0;
  border-top: 10px solid transparent; /* Adjust size to change the arrow */
  border-bottom: 10px solid transparent; /* Adjust size to change the arrow */
  border-left: 10px solid var(--color-dark-blue); /* Border color should match the box's background */
  border-right: 10px solid transparent; /* This creates the arrow effect */
}

.tooltip p {
    margin: 0;
    padding: 0.4em;
    color: white;
}

.text-holder {
    flex: 2;
    text-align: left;
    margin-left: 2em;
}

.text-field {
    border: 2px solid transparent;
    background-color: transparent;
    width: 100%;
    max-width: 100%;
    padding: 2px;
}

.text-field-incorrect {
    border: 2px solid rgba(255, 0, 0, 0.3) !important;
    border-radius: 10px;
}

.text-field-active {
    cursor: pointer;
}

.text-field[readonly] {
    outline: none;
}

.text-field-active:hover:not([readonly]), .text-field-active:focus:not([readonly]) {
    border: 2px solid var(--color-light-blue);
    background-color: #fff;
    box-shadow: 3px 3px 5px var(--color-bright-blue-shadow);
    border-radius: 10px;
    outline: none;
}

.title {
    font-size: 24px;
    font-weight: 900;
}

.description {
    font-size: 16px;
    font-weight: 500;
}

.discount-details {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
}

.bubble {
    background-color: white;
    color: var(--color-bright-blue);
    border: 2px solid var(--color-light-blue);
    border-radius: 10px;
    margin-left: 0.35em;
    margin-right: 0.35em;
    padding-right: 0.15em;
    padding-left: 0.15em;
    font-size: 2em;
    font-weight: 900;
    min-width: 55px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bubble-incorrect {
    border: 2px solid rgba(255,0,0,0.3);
}

.percent-sign {
    margin-top: 0.3em;
    margin-right: 0.1em;
    font-size: 0.65em;
}
</style>